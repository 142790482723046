





import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'progress-bar-custom': () => import('@/modules/popo/components/progress-bar-custom.vue'),
    'popo-info': () => import('@/modules/popo/components/details/popo-info.vue'),
  },
})
export default class extends Vue {}
